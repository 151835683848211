import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import './styles.scss';

/** A simple `section` element that provides some spacing */

const Section = ({ className, children, ...other }) => (
  <section className={classNames('section', className)} {...other}>
    {children}
  </section>
);

Section.defaultProps = {
  className: null,
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default Section;
