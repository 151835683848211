import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';
import useImageQuery from '../../hooks/useImageQuery';

/**
 * The `Image` component uses gatsby-image to generate optimized images. Learn more at https://www.gatsbyjs.com/plugins/gatsby-image/.
 */
const Image = ({ filename, alt, ...other }) => {
  const { findImageNodeByPath, findImageNodeByFilename } = useImageQuery();

  if (filename.startsWith('https://') || filename.startsWith('http://')) {
    return <img src={filename} alt={alt} {...other} />;
  }

  let imageNode = findImageNodeByPath(filename);
  if (!imageNode) {
    imageNode = findImageNodeByFilename(filename);
  }

  if (!imageNode) {
    // No matching file found.
    return null;
  }

  // Handle edge case images
  if (!imageNode.childImageSharp || imageNode.extension === 'svg') {
    return <img alt={alt} src={imageNode.publicURL} {...other} />;
  }

  // Most images return here.
  return (
    <Img
      loading="eager"
      fluid={imageNode.childImageSharp.fluid}
      {...other}
    />
  );

};

Image.defaultProps = {
  alt: '',
};

Image.propTypes = {
  /** A filename */
  filename: PropTypes.string.isRequired,
  /** A text description of the image */
  alt: PropTypes.string,
};

export default Image;
