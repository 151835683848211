import React, { useState, useEffect, useRef } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import { useLocation, globalHistory } from '@reach/router';
import classNames from 'classnames';
import { Container } from '../Grid';
import Share from '../Share';
import CountrySwitcher from '../CountrySwitcher';
import Button from '../Button';
import Image from '../Image';
import Icon from '../Icon';
import './styles.scss';

/**
 * A global header component that is populated with properties from `gatsby-config.js`
 */

const Header = () => {
  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);
  /** If we are below the header area, 'isSticky' controls whether the sticky nav shows */
  const [isSticky, setIsSticky] = useState(false);
  /** 'isOffPage' indicates we are below the header area, let 'isSticky' take over */
  const [isOffPage, setIsOffPage] = useState(false);
  const [viewportWidth, setViewportWidth] = useState(
    typeof window !== `undefined` ? window.innerWidth : null
  );

  const header = useRef(null);
  const headerUpper = useRef(null);
  const headerLower = useRef(null);
  const logo = useRef(null);
  const partnerLogos = useRef(null);
  const menu = useRef(null);

  const { pathname: currentPath } = useLocation();

  const isHome = currentPath === '/';

  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          partnerName
          partnerLogo
          programName
          programLogo
          menuLinks {
            label
            path
          }
        }
      }
    }
  `);

  useEffect(() => {
    const threshold = 80;
    let ticking = false;
    let lastScrollY = window.pageYOffset;

    const mobileMenuHeight = isMobileMenuVisible
      ? menu.current.offsetHeight
      : 0;
    const headerHeight = header.current.offsetHeight + mobileMenuHeight;
    const headerUpperStyles = window.getComputedStyle(header.current);
    const headerUpperPaddingTop = parseInt(
      headerUpperStyles.getPropertyValue('padding-top'),
      10
    );

    // This is the height of the top utility bar. which is hidden
    // in sticky mode.
    const stickyHeaderOffset =
      headerUpper.current.offsetHeight + headerUpperPaddingTop;

    if (isSticky) {
      header.current.style.transform = `translateY(-${stickyHeaderOffset}px)`;
    } else {
      header.current.style.transform = '';
    }

    const toggleHeader = () => {
      const scrollY = window.pageYOffset;

      // If we are past the header
      if (scrollY > headerHeight) {
        // Mark the fact the we are below the header
        if (!isOffPage) setIsOffPage(true);
        // If we are scrolling up OR down mobile gets hidden
        if (isMobileMenuVisible) setIsMobileMenuVisible(false);

        // If the scroll length was less then threshold, ignore the scroll.
        // This prevents very slight scrolls from triggering animations.
        if (Math.abs(scrollY - lastScrollY) < threshold) {
          ticking = false;
          return; // RETURN EARLY
        }
      }

      // We are moving DOWN
      if (scrollY > lastScrollY) {
        if (isSticky) setIsSticky(false);
      } else if (scrollY > stickyHeaderOffset && scrollY < lastScrollY) {
        // We are moving UP, and we are still below the utility nav
        if (isMobileMenuVisible) setIsMobileMenuVisible(false);
        if (!isSticky) setIsSticky(true);
      }

      // If we are near the top utility nav
      // the header should always show.
      if (scrollY <= stickyHeaderOffset) {
        if (isOffPage) setIsOffPage(false);
        if (isSticky) setIsSticky(false);
      }

      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(toggleHeader);
        ticking = true;
      }
    };

    const handleResize = () => {
      if (typeof window !== `undefined`) {
        setViewportWidth(window.innerWidth);

        if (window.innerWidth >= 1200 && isMobileMenuVisible) {
          setIsMobileMenuVisible(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, [isSticky, isOffPage, isMobileMenuVisible, viewportWidth]);

  const handleHamburgerClick = () => {
    setIsMobileMenuVisible(!isMobileMenuVisible);
  };

  const renderPartnerLogos = (scaled, hidden) => (
    <div
      className={classNames(
        'header__partner-logos',
        (hidden || (!isHome && isSticky)) && 'header__partner-logos--hidden'
      )}
      ref={partnerLogos}
    >
      <div
        className={classNames(
          'header__de-logo',
          scaled && 'header__de-logo--scaled'
        )}
      >
        <Image filename="de-logo.png" alt="Discovery Education" />
      </div>
      <div className="header__logo-divider" />
      <div
        className={classNames(
          'header__partner-logo',
          scaled && 'header__partner-logo--scaled'
        )}
      >
        <Image
          filename={site.siteMetadata.partnerLogo}
          alt={site.siteMetadata.partnerName}
        />
      </div>
    </div>
  );

  return (
    <header
      className={classNames('header', isOffPage && 'header--off-page')}
      ref={header}
    >
      <Container className="header__container" fullWidth>
        <div
          className={classNames(
            'header__upper',
            isHome && 'header__upper--home'
          )}
          ref={headerUpper}
        >
          {renderPartnerLogos(true, isHome)}
          <div className="header__utility-menu">
            <div className="header__signup">
              <Button
                className="header__signup-button"
                link
                to="https://info1.discoveryeducation.com/girls4tech?cep=girls4tech"
                target="_blank"
                rel="noopener noreferrer"
              >
                Updates <Icon name="signup" marginLeft />
              </Button>
            </div>
            <div className="header__share">
              <Share inDropDown />
            </div>
            {/* <div className="header__language-switcher">
              <CountrySwitcher />
            </div> */}
          </div>
        </div>
        <div className="header__lower" ref={headerLower}>
          {isHome ? (
            renderPartnerLogos(false)
          ) : (
            <div className="header__program-logo" ref={logo}>
              <Link to="/">
                <Image
                  filename={site.siteMetadata.programLogo}
                  alt={site.siteMetadata.programName}
                />
              </Link>
            </div>
          )}
          <div className="header__hamburger">
            <Button
              link
              className={classNames(
                'header__hamburger-button',
                isMobileMenuVisible && 'header__hamburger-button--active',
                isSticky && 'header__hamburger-button--in-sticky'
              )}
              onClick={handleHamburgerClick}
            >
              <div className="header__hamburger-button-inner"></div>
            </Button>
          </div>
          <div
            className={classNames(
              'header__menus',
              isMobileMenuVisible && 'header__menus--visible'
            )}
            ref={menu}
          >
            <nav className="header__main-menu">
              <ul className="header__main-menu-list">
                {site.siteMetadata.menuLinks.map((link, i) => (
                  <li key={i} className="header__main-menu-list-item">
                    <Link
                      activeClassName="header__main-menu-link--active"
                      to={link.path}
                      className="header__main-menu-link"
                    >
                      {link.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </nav>
            {isMobileMenuVisible && (
              <>
                <div className="header__signup">
                  {/* <CountrySwitcher /> */}
                  <Button
                    className="header__signup-button"
                    link
                    to="https://info1.discoveryeducation.com/girls4tech?cep=girls4tech"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Updates <Icon name="signup" marginLeft />
                  </Button>
                </div>
                <div className="header__share">
                  <Share inDropDown />
                </div>
              </>
            )}
          </div>
        </div>
      </Container>
    </header>
  );
};

export default Header;
