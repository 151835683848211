import Icon from '../Icon';
import Button from '../Button';
import PropTypes from 'prop-types';
import React from 'react';

const OutboundLink = ({
  children,
  to,
  withIcon,
  className,
  asButton,
  asInverseButton,
  ...props
}) => {
  return asButton || asInverseButton ? (
    <Button
      to={to}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
      inverse={asInverseButton}
    >
      {children}
      {withIcon && <Icon name="externallink" marginLeft />}
    </Button>
  ) : (
    <a
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
      {...props}
    >
      {children}
      {withIcon && <Icon name="externallink" marginLeft />}
    </a>
  );
};

OutboundLink.propTypes = {
  children: PropTypes.node.isRequired,
  /** The URL to visit */
  to: PropTypes.string.isRequired,
  /** Display an outbound icon */
  withIcon: PropTypes.bool,
  /** Style the link as a button */
  asButton: PropTypes.bool,
  /** Style the link as an inverted button */
  asInverseButton: PropTypes.bool,
  /** A custom class name */
  className: PropTypes.string,
};

export default OutboundLink;
